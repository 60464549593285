// CookieNotice.js

import React from 'react';
import { Snackbar, Button } from '@mui/material';

const CookieNotice = ({ open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message="Este sitio web utiliza cookies para mejorar la experiencia del usuario."
      action={
        <Button color="secondary" size="small" onClick={onClose}>
          Aceptar
        </Button>
      }
    />
  );
};

export default CookieNotice;

const apiKey = 'b0a3331b33f640e6a549874ac3254858';
const pageId = 'h4x7gwf1rcd9';
const metricId = '0mjg8wx836hd';
const apiBase = 'https://api.statuspage.io/v1';

const url = `${apiBase}/pages/${pageId}/metrics/${metricId}/data.json`;
const authHeader = { 'Authorization': `OAuth ${apiKey}`, 'Content-Type': 'application/json' };

// Calculate the timestamp for the data point
const currentTimestamp = Math.floor(Date.now() / 1000);
const randomValue = Math.floor(Math.random() * 1000);

const data = {
  data: {
    timestamp: currentTimestamp,
    value: randomValue,
  },
};

fetch(url, {
  method: 'POST',
  headers: authHeader,
  body: JSON.stringify(data),
})
.then(response => {
  if (!response.ok) {
    throw new Error(`Error: ${response.statusText}`);
  }
  return response.json();
})
.then(() => {
  console.log('Data submitted successfully');
})
.catch(error => {
  console.error(`Error caught: ${error.message}`);
});


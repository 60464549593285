import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, Annotation } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, annotationPlugin);

const LineChart = ({ data }) => {
  const [pointSize, setPointSize] = useState(5);
  const [currentTime, setCurrentTime] = useState(new Date());

  // Function to adjust point size based on window width
  const adjustPointSize = () => {
    const windowWidth = window.innerWidth;
    setPointSize(Math.max(3, windowWidth / 250)); // Tamaño mínimo de 3
  };

  // Update point size on window resize
  useEffect(() => {
    adjustPointSize();
    window.addEventListener('resize', adjustPointSize);
    return () => window.removeEventListener('resize', adjustPointSize);
  }, []);

  // Update current time every second
  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  // Function to get color based on value
  const getColor = (value) => {
    if (value <= 0.11) return 'green';
    if (value <= 0.165) return 'orange';
    if (value <= 0.22) return 'red';
    return 'purple';
  };

  // Create chart data
  const colors = data.map(item => getColor(item.value));

  const chartData = {
    labels: data.map(item => new Date(item.datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })),
    datasets: [
      {
        label: 'Precio',
        data: data.map(item => item.value),
        borderColor: '#000000',
        backgroundColor: data.map(item => `${getColor(item.value)}60`),
        borderWidth: 2,
        pointBackgroundColor: colors,
        pointBorderColor: colors,
        pointBorderWidth: 2,
        pointRadius: pointSize
      }
    ]
  };

  // Determine the index of the label corresponding to the current time minus one hour
  const oneHourAgo = new Date(currentTime.getTime() - 60 * 60 * 1000);
  const oneHourAgoLabel = oneHourAgo.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  const nowLabel = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  
  const oneHourAgoIndex = chartData.labels.findIndex(label => label >= oneHourAgoLabel);
  const nowIndex = chartData.labels.findIndex(label => label >= nowLabel);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `€${tooltipItem.raw.toFixed(4)}`;
          }
        }
      },
      annotation: {
        annotations: {
          verticalLine: {
            type: 'line',
            xMin: oneHourAgoIndex >= 0 ? oneHourAgoIndex : chartData.labels.length - 1, // Ensure it points to the latest available index
            xMax: oneHourAgoIndex >= 0 ? oneHourAgoIndex : chartData.labels.length - 1,
            borderColor: 'red',
            borderWidth: 2,
            label: {
              content: 'Hora Actual Menos 1 Hora',
              enabled: true,
              position: 'top',
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              font: {
                size: 12
              },
              padding: {
                top: 4,
                bottom: 4
              }
            }
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hora'
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          callback: (value, index, values) => {
            // Rotate labels to avoid overlapping
            return index % 2 === 0 ? value : '';
          }
        }
      },
      y: {
        title: {
          display: true,
          text: 'Precio (€)'
        },
        beginAtZero: true,
        ticks: {
          callback: (value) => `€${value}`
        }
      }
    }
  };

  return <Line data={chartData} options={options} />;
};

export default LineChart;


import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { BrowserRouter as Router} from 'react-router-dom';
import { Container, Grid, Paper, Typography, CircularProgress, IconButton, Tooltip, Button, Snackbar, Alert, TextField, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faEquals, faClock, faChartLine, faCalendar, faLightbulb, faWarning, faCircleExclamation, faInformation } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useInView } from 'react-intersection-observer';
import { LocalNotifications } from '@capacitor/local-notifications';
import { CSSTransition } from 'react-transition-group';
import Calendar from 'react-calendar';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import './App.css';
import moment from 'moment-timezone';
import './animations.css';
import CookieNotice from './CookieNotice';
import CryptoJS from 'crypto-js';
import LineChart from './LineChart';
import config from './config.json';

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [currentPrice, setCurrentPrice] = useState(null);
  const [showWarningScreen, setShowWarningScreen] = useState(false);
  const [priceTrend, setPriceTrend] = useState('');
  const [nextBestPrice, setNextBestPrice] = useState({ hour: null, price: null });
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [calculatedCost, setCalculatedCost] = useState(null);
  const [notificationTooHigh, setNotificationTooHigh] = useState(false);
  const [yesterdayAveragePrice, setYesterdayAveragePrice] = useState(null);
  const [showCookiePolicy, setShowCookiePolicy] = useState(false);
  const [showLineChart, setShowLineChart] = useState(false);
  //const secretKey = '727Nh4jRvWrHA70R5gdaDlGmP5zF7pFIzGPFdZ4qWSaDfwxFAGoLhM2RQRbcYZCZufVXVH5PhFC2IhepUhWLWsqnXuMYOJZ3AKUMrQFq2v5Cvoc0d163NrRLbMP5RSDDr9fzGXydged7fLBPyeKAYUZt7IZaegxPe5Qh6aQuViAofspdBO4FGJcxwSStDdzpC7bsbF1q6nOqAL7YVt1bpMNORH2lXJwPoeKkbPdMKAFUNarqaJavkgDNkcphzwWnmPYwu9heWXGhUrQT4g64bdc5vaKDuDInHDd8mPKSbDGAY13U2BaGm72q0jhdFDw1Uf0RSwYDpIwEN19i9FDm0ULwr3JbUt7paMHJ8AH9NUBbpxBiKueHF2zu0tfGafQ65fq4nGZggov4IMRiIQJ6j4EXszD4q921woqGhHwNpRJJIFtJ8ysBrfbiJAcSg67bn0Q05XgVXSVD1SrSZI8SrlfcacoAOBNODkEdZPSjUTnhi4lIDRvTij2LHxrn61Xe9cxPOwj1H2RfU9f8KUuJdZiYvKXwHLsrbvQO4m3ymJxOFKkXxRRYwQuoH7W2m6SCwZV1q76JGhsF6Mw3RWZGn8BSVayBeVrnkewGgKMwOJ85bLqdWRsi76DtIBAcelevBPKJvwwgaeFBGDXI3NKOzfnk6epNMC6wEwcGzOEWMxZLsoZUWzLc2YXrRGPBO3kyyiGV0ZaBx4WCPf7Xn7VOwfsvRwWLBsqzOgq61MLIivST82gkxFDeT0B6fekDu0RbZfSW6viYYfPOoeGFrHUhJEj4uycGL4QS2oxnvX5PocjOGRMVLyillH1aT03ihfRIlrrFlNd1Q2XYb5g37TQpKNHStgp3ATtZqLu8I9Q0PhAsDSiCS9cN1ItryMQkuG9wuo8PvNVuuWSDD7eYVkLpzuFTWP30dwYGhhXpN6kh2jaQVcpyovSk63dZ4U8qjxdp0Um6sdvD3WCgdcppmN01ZUYc0pPi9S9AFOKv7YF69UxtRNq7chDCSPckGwkQqSDeZqyz5Ot40Uqnp5ZVtB3yclq1QaNYPpZVh2WHRoG3LAEHKIb4vFJDxzc';  
  const [showMaintenanceNotice, setshowMaintenanceNotice] = useState(false); //EDITAR CUANDO HAY MANTENIMIENTO
  const [showLegalNotice, setShowLegalNotice] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [priceChangePercentage, setPriceChangePercentage] = useState(null);
  const maintenanceEndTime = Math.floor(new Date('2024-07-28T15:00:00Z').getTime() / 1000);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [lastWeekAveragePrice, setLastWeekAveragePrice] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false); // Estado para mostrar/ocultar el calendario
  const [showCookieNotice, setShowCookieNotice] = useState(false); // Estado para mostrar el aviso de cookies
  const currentHour = moment().tz('Europe/Madrid').hours();
  const [duration, setDuration] = useState('');
  const today = new Date().toDateString();
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [colorIndex, setColorIndex] = useState(0);
  const currentTime = moment().tz('Europe/Madrid').format('HH:mm');
  const convertToCSV = (data) => {
    // Cabecera con columnas separadas por punto y coma
    const header = ['Hora', 'Precio', 'Unidad'];
    
    // Generamos las filas con la hora, precio y unidad (€/kWh) separadas por punto y coma
    const rows = data.map(item => {
      const itemDate = new Date(item.datetime);
      const itemLocalHour = itemDate.toLocaleString('en-US', { hour: '2-digit', hour12: false });
      return [itemLocalHour, item.value.toFixed(4), '€/kWh'];
    });
  
    // Convertimos las filas en un string CSV, separado por punto y coma
    return [header, ...rows].map(row => row.join(';')).join('\n');
  };
  
  const downloadCSV = () => {
    const csv = convertToCSV(data);
  
    // Forzar la codificación en UTF-8 con el marcador BOM
    const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });
    
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `precio_luz_precionline_${formatDate(selectedDate)}.csv`;  
    a.click();
    window.URL.revokeObjectURL(url);
  };  
  const isAfterEightThirtyFivePM = currentTime > '20:30';
  //const decodeApiKey = (encryptedKey) => {
    //const bytes = CryptoJS.AES.decrypt(encryptedKey, secretKey);
    //return bytes.toString(CryptoJS.enc.Utf8);
  //};
  const [feedback, setFeedback] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [showFeedbackBox, setShowFeedbackBox] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    // Función para enviar notificación al webhook de Discord
    const notifyDiscord = async () => {
      try {
        const payload = {
          username: "Website Notifier",
          content: "Un usuario ha ingresado a la página.",
        };

        // Enviar la notificación al webhook de Discord
        await fetch('https://discord.com/api/webhooks/1280998052026650697/D8f2sCh27hjl4EsIHkIwjkt_oAers93ivqQTFS4wR6LUHpS1jwDWK5XwN4_kXJohKtCZ', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
      } catch (error) {
        console.error('Error al enviar la notificación:', error);
      }
    };

    // Llamar a la función cuando el componente se monte
    notifyDiscord();
  }, []);
  const webhookUrl = "https://discord.com/api/webhooks/1280285986978267249/uAd8viFlqGD-olcxlhTeASK8h3bTDedNWXmKwdmC33DgbFPmB34JoCtnj9bbZeclkhYz"; 
  const userInfoUrl = "https://ipinfo.io?token=d6e1b5ded035a5";

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(userInfoUrl);
        if (!response.ok) throw new Error("No se pudo obtener la información del usuario.");
        const data = await response.json();
        setUserInfo(data);
      } catch (error) {
        console.error("Error al obtener la información del usuario:", error);
      }
    };
    fetchUserInfo();
  }, []);

  const sendFeedbackToDiscord = async (response, userComment = "") => {
    setIsSubmitting(true);
    try {
      const ipInfo = userInfo
        ? `IP: ${userInfo.ip}\nCiudad: ${userInfo.city}\nRegión: ${userInfo.region}\nPaís: ${userInfo.country}\nProveedor de Internet: ${userInfo.org}`
        : "No se pudo obtener la información del usuario.";

      const bodyContent = {
        content: `El usuario seleccionó: ${response}\n${ipInfo}\nComentario adicional: ${userComment || "Sin comentario adicional"}`,
      };

      const res = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyContent),
      });

      if (!res.ok) throw new Error("Error al enviar la respuesta al webhook de Discord.");

      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setShowFeedbackBox(false);
        }, 1000);
      }, response === "Sí" ? 15000 : 1000); // Tiempo para la animación del corazón

    } catch (error) {
      console.error("Error al enviar la respuesta al webhook de Discord:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFeedback = (response) => {
    setFeedback(response);
    if (response === "Sí") {
      sendFeedbackToDiscord(response);
    }
  };

  const handleSubmit2 = () => {
    if (comment.trim() === "") {
      alert("Por favor, escribe un comentario antes de enviar.");
      return;
    }
    sendFeedbackToDiscord("No", comment);
  };

  const handleRetry = () => {
    setFeedback(null);
    setComment("");
  };
  //const encodedApiKey = 'U2FsdGVkX186UQ11NhHP+lz6WxVYn99OAwhL+3H0WEBPkYgynIZAU3KAdrpSsk/M'; 
  const apiKey = config.REACT_APP_API_KEY;
  
  const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const CustomSpinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #3f51b5;
    border-color: #3f51b5 transparent #3f51b5 transparent;
    animation: ${spin} 1.2s linear infinite;
  }
`;

  const safeToISOString = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return date.toISOString();
    } else {
      console.error('Invalid date provided to safeToISOString:', date);
      return '';
   }
};

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const [timeRemaining, setTimeRemaining] = useState(maintenanceEndTime - Math.floor(Date.now() / 1000));
  const getMillisecondsUntilMidnight = () => {
  const now = moment().tz('Europe/Madrid');
  const midnight = moment.tz('Europe/Madrid').endOf('day');

  const timeDiff = midnight.diff(now);
  const hours = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

  console.log(`Tiempo restante hasta medianoche: ${hours} horas, ${minutes} minutos y ${seconds} segundos`);
};

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const remainingTime = maintenanceEndTime - currentTime;
      setTimeRemaining(remainingTime);
    };

    calculateTimeRemaining(); // Initialize the timer

    const interval = setInterval(calculateTimeRemaining, 600 * 1000);

    return () => clearInterval(interval);
  }, [maintenanceEndTime]);

  const formatTime = (seconds) => {
    if (seconds <= 0) return "00:00:00";
    
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const toggleCookiePolicy = () => {
    setShowCookiePolicy(!showCookiePolicy);
  };
  const toggleLegalNotice = () => {
    setShowLegalNotice(!showLegalNotice);
  };
  const toggleLineChart = () => {
    setShowLineChart(!showLineChart);
  };
  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };
  
  const getMinDate = () => {
    // La fecha mínima es 30 días atrás desde la fecha actual
    return new Date(new Date().setDate(new Date().getDate() - 30));
  };
  
  const goToPreviousDayBtn = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
    const formattedStartDate = newDate.toISOString().split('T')[0] + 'T00:00';
    const formattedEndDate = new Date(newDate.setDate(newDate.getDate() + 1)).toISOString().split('T')[0] + 'T00:00';
    const url = ``;
    localStorage.setItem('apiUrl', url);
    fetchData(url); // Llama a fetchData con la nueva URL
  };
  const goToPreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    
    // Verifica que la nueva fecha no sea más antigua que 30 días atrás
    if (newDate < new Date(new Date().setDate(new Date().getDate() - 30))) {
      newDate.setDate(new Date().getDate() - 30);
    }
    
    setSelectedDate(newDate);
    const formattedStartDate = newDate.toISOString().split('T')[0] + 'T00:00';
    const formattedEndDate = new Date(newDate.setDate(newDate.getDate() + 1)).toISOString().split('T')[0] + 'T00:00';
    const url = ``;
    localStorage.setItem('apiUrl', url);
    fetchData(url);
  };
  
  const [power, setPower] = useState('');
  const reloadPage = () => {
    window.location.reload();
    localStorage.clear()
  }
  const [bestTime, setBestTime] = useState(null);
    // Verifica si LocalNotifications está disponible
    const isLocalNotificationsAvailable = () => {
      return typeof LocalNotifications !== 'undefined';
    };
    const notifyUser = async (title, body) => {
      if (isLocalNotificationsAvailable()) {
        try {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: title,
                body: body,
                id: new Date().getTime(),
                schedule: { at: new Date(Date.now() + 1000) },
              }
            ]
          });
        } catch (err) {
          console.error('Error al enviar notificación local:', err);
        }
      } else {
        // Solo loguea el mensaje si LocalNotifications no está disponible
        console.log(`${title}: ${body}`);
      }
    };
  const getWeekOfYear = (date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil(((date - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  };
  const goToNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate());
    setSelectedDate(newDate);
    const formattedStartDate = newDate.toISOString().split('T')[0] + 'T00:00';
    const formattedEndDate = new Date(newDate.setDate(newDate.getDate() + 1)).toISOString().split('T')[0] + 'T00:00';
    const url = `https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/nextday`;
    localStorage.setItem('apiUrl', url);
    fetchData(url); // Llama a fetchData con la nueva URL
  };
  
  const findNextBestPrice = (data) => {
  // Obtener la hora actual
  const currentHour = new Date().getHours();
  console.log("Current Hour:", currentHour); // Depuración

  // Filtrar las horas futuras y buscar la de menor precio
  const futurePrices = data.filter(item => new Date(item.datetime).getHours() > currentHour);

  if (futurePrices.length === 0) {
    console.log("No future hours found. Returning current or default.");
    return { price: null, hour: null };
  }

  // Encontrar el item con el menor precio
  const nextBestPriceData = futurePrices.reduce((minItem, currentItem) => 
    currentItem.value < minItem.value ? currentItem : minItem
  );

  const nextBestPriceHour = new Date(nextBestPriceData.datetime).getHours();
  console.log("Returning Next Best Price:", {
    hour: nextBestPriceHour,
    price: nextBestPriceData.value.toFixed(4)
  });

  return {
    hour: nextBestPriceHour,
    price: nextBestPriceData.value.toFixed(4)
  };
};

  const getProgressBarColor = (percentage) => {
    if (percentage <= 37) return 'green';
    if (percentage <= 56) return 'orange';
    if (percentage <= 75) return 'red';
    return 'purple';
  };
  const [tableSaved, setTableSaved] = useState(false);

  // Función para cerrar el aviso de cookies
  const handleCloseCookieNotice = () => {
    setShowCookieNotice(false);
  };
  useEffect(() => {
    const storedDate = localStorage.getItem('selectedDate');
    if (storedDate) {
      setSelectedDate(new Date(storedDate));
    } else {
      // Si no hay fecha guardada, establece la fecha actual
      const today = new Date();
      setSelectedDate(today);
      localStorage.setItem('selectedDate', today.toISOString());
    }
    initializePage();
    const interval = setInterval(fetchData, 600 * 1000);
    return () => clearInterval(interval);
  }, []);
  
const initializePage = async () => {
  try {
    const today = new Date();
    const todayStr = today.toISOString().split('T')[0];
    
    // Obtener la fecha seleccionada y la URL de localStorage
    const storedDateStr = localStorage.getItem('selectedDate');
    const storedUrl = localStorage.getItem('apiUrl');
    
    if (storedDateStr) {
      localStorage.clear();
      const storedDate = new Date(storedDateStr);
      const storedDateStrShort = safeToISOString(storedDate).split('T')[0];
      
      if (storedDateStrShort !== todayStr) {
        // La fecha guardada no es hoy, eliminar todos los datos y redirigir a hoy
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('apiUrl');
        window.location.reload(); // Recargar para establecer la fecha actual
      } else {
        // La fecha guardada es hoy, usar la URL guardada
        if (storedUrl) {
          localStorage.clear();
          await fetchData(storedUrl);
        } else {
          localStorage.clear();
          await fetchData('https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/actual');
        }
      }
    } else {
      // No hay fecha guardada, usar URL por defecto
      await fetchData('https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/actual');
    }
  } catch (error) {
    console.error('Error en initializePage:', error);
  }
};

// Llama a initializePage al cargar la página
window.addEventListener('load', initializePage);

const formatDate = (date) => {
  try {
      if (!(date instanceof Date) || isNaN(date)) {
          console.error('Fecha inválida proporcionada a formatDate.');
          return null;
      }
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
  } catch (error) {
      console.error('Error formateando la fecha:', error);
      return null;
  }
};

// Función existente para manejar la selección de fechas
const handleCalendarDateSelect = async (selectedDate) => {
  try {
      if (!(selectedDate instanceof Date) || isNaN(selectedDate)) {
          console.error('selectedDate no es un objeto Date válido.');
          return;
      }
      
      const minDate = getMinDate();
      
      if (selectedDate < minDate) {
          // Si la fecha seleccionada es antes de la fecha mínima permitida, ajusta al límite permitido
          selectedDate = new Date();
          selectedDate.setDate(selectedDate.getDate() - 30);
      }
    
      // Almacenar la fecha seleccionada en localStorage en el formato DD/MM/YYYY
      const formattedSelectedDate = formatDate(selectedDate);
      if (formattedSelectedDate) {
          localStorage.setItem('selectedDate', formattedSelectedDate);
      } else {
          console.error('No se pudo formatear la fecha seleccionada.');
          return;
      }
      
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1); // Día siguiente
    
      let url = 'https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/actual'; // URL por defecto
      if (selectedDate.toDateString() === today.toDateString()) {
          // URL para el día actual
          url = 'https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/actual';
      } else {
          const formattedStartDate = new Date(selectedDate);
          formattedStartDate.setDate(selectedDate.getDate()); // Sumar un día al start_date
          const formattedEndDate = new Date(formattedStartDate);
          formattedEndDate.setDate(formattedStartDate.getDate() + 1); // Sumar un día al end_date
    
          const startDateStr = formatDate(formattedStartDate); // Usar formato DD/MM/YYYY
          const endDateStr = formatDate(formattedEndDate) + 'T00:00'; // Usar formato DD/MM/YYYY
    
          if (startDateStr && endDateStr) {
              url = `https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/query?date=${startDateStr}`;
          } else {
              console.error('No se pudieron formatear las fechas para la URL.');
              return;
          }
      }
    
      // Guardar la URL en localStorage para usarla después de recargar
      localStorage.setItem('apiUrl', url);
    
      // Recargar la página para actualizar los datos
      await fetchData(url); // Llama a fetchData con la URL actualizada
  } catch (error) {
      console.error('Error en handleCalendarDateSelect:', error);
  }
};

  const handleNotificationClose = () => {
    setShowNotification(false);
  };
  
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const calculateYesterdayAveragePrice = () => {
    const yesterdayData = data.filter(item => new Date(item.datetime).getDate() === new Date().getDate() - 1);
    if (yesterdayData.length > 0) {
      const yesterdayAverage = yesterdayData.reduce((acc, item) => acc + item.value, 0) / yesterdayData.length;
      setYesterdayAveragePrice(yesterdayAverage.toFixed(4));
    } else {
      setYesterdayAveragePrice(null);
    }
  };
  
  const calculateLastWeekAveragePrice = () => {
    const currentWeek = getWeekOfYear(new Date());
    const lastWeekData = data.filter(item => getWeekOfYear(new Date(item.datetime)) === currentWeek - 1);
    if (lastWeekData.length > 0) {
      const lastWeekAverage = lastWeekData.reduce((acc, item) => acc + item.value, 0) / lastWeekData.length;
      setLastWeekAveragePrice(lastWeekAverage.toFixed(4));
    } else {
      setLastWeekAveragePrice(null);
    }
  };

  
  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };
  
  const handlePowerChange = (event) => {
    setPower(event.target.value);
  };
  
  const findBestTimeToUse = () => {
    if (!duration || !power) {
      setNotificationMessage("Por favor, introduce la duración y el consumo de energía.");
      setShowNotification(true);
      return;
    }
  
    const totalConsumption = parseFloat(power) * parseFloat(duration); // Consumo total de energía en kWh
    const currentHour = new Date().getHours();
    let bestHour = null;
    let bestCost = Infinity;
  
    // Iterar solo sobre las horas a partir de la hora actual
    for (let i = currentHour; i <= 24 - parseInt(duration); i++) {
      // Calcular el costo total para el intervalo de horas actual
      const currentCost = data.slice(i, i + parseInt(duration)).reduce((acc, item) => acc + item.value, 0);
  
      // Calcular el costo ajustado por kWh
      const adjustedCost = currentCost / parseFloat(duration);
  
      // Debugging: Imprimir valores clave para verificar
      console.log(`Intervalo horario: ${i} - ${i + parseInt(duration) - 1}`);
      console.log(`Costo actual: ${currentCost.toFixed(2)}`);
      console.log(`Costo ajustado: ${adjustedCost.toFixed(2)}`);
  
      if (adjustedCost < bestCost) {
        bestCost = adjustedCost;
        bestHour = i;
      }
    }
  
    if (bestHour !== null) {
      setBestTime({ hour: bestHour, cost: (bestCost * totalConsumption).toFixed(2) }); // Multiplicar por totalConsumption para obtener el costo total ajustado
    } else {
      setBestTime(null);
    }
  };

  // Función para calcular el costo total en euros
  const calculateCost = () => {
    if (!duration || !power) {
      setNotificationMessage("Por favor, introduce la duración y el consumo de energía.");
      setShowNotification(true);
      return;
    }
  
    const totalConsumption = parseFloat(power) * parseFloat(duration); // Consumo total de energía en kWh
    const hours = parseInt(duration); // Horas de duración
    let minCost = Infinity;
  
    for (let i = 0; i <= 24 - hours; i++) {
      // Sumar los precios de las horas correspondientes
      const currentCost = data.slice(i, i + hours).reduce((acc, item) => acc + item.value, 0);
      
      // Debugging: Imprimir valores clave para verificar
  
      // Calcular el costo ajustado para el consumo total de energía en euros
      const adjustedCost = currentCost * totalConsumption;
      // Mantener el costo mínimo encontrado
      if (adjustedCost < minCost) {
        minCost = adjustedCost;
      }
    }
  
    // Asignar el costo mínimo encontrado
    console.log(`Costo mínimo encontrado: ${minCost.toFixed(2)}`);
    setCalculatedCost(minCost.toFixed(2));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    findBestTimeToUse();
    calculateCost();
  };
  useEffect(() => {
    calculateYesterdayAveragePrice();
    calculateLastWeekAveragePrice();
    setShowCookieNotice(false); // Desactivado temporalmente ya que no es necesario. Cambiar valor bool a true cuando sea necesario.
  }, [data]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 600 * 1000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = useCallback(async (url = 'https://humorous-enjoyment-production-8f73.up.railway.app/precionline/api/v1/prices/actual') => {  
    setLoading(true);
  
    try {
      const response = await axios.get(url, {
        headers: {
          'Accept': 'application/json, application/vnd.esios-api-v1+json',
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
          'App-Type': 0          

       }
      });
    
      // Procesar los datos de la respuesta
      const values = response.data.indicator.values.map((item, index, array) => {
        const previousItem = array[index - 1];
        const changePercentage = previousItem
          ? ((item.value - previousItem.value) / previousItem.value) * 100
          : 0;
  
        return {
          datetime: item.datetime,
          value: item.value / 1000,
          changePercentage: changePercentage.toFixed(2),
          geo_id: 8741
        };
      }).reduce((acc, current) => {
        const x = acc.find(item => new Date(item.datetime).getHours() === new Date(current.datetime).getHours());
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);  
      setData(values);
      const currentHour = new Date().getHours();  
      const currentPriceData = values.find(item => new Date(item.datetime).getHours() === currentHour);  
      if (currentPriceData) {
        setCurrentPrice(currentPriceData.value.toFixed(4));
        setShowWarningScreen(false); // Ocultar pantalla de advertencia
  
        if (currentPriceData.value <= 0.11) {
          await notifyUser('Precio de electricidad muy bajo', `El precio actual es ${currentPriceData.value} €/kWh.`);
        } else if (currentPriceData.value >= 0.20) {
          await notifyUser('Precio de electricidad muy alto', `El precio actual es ${currentPriceData.value} €/kWh.`);
        }
  
        const previousHourPriceData = values.find(item => new Date(item.datetime).getHours() === currentHour - 1);
        console.debug('Previous hour price data:', previousHourPriceData);
  
        if (previousHourPriceData) {
          const changePercentage = ((currentPriceData.value - previousHourPriceData.value) / previousHourPriceData.value) * 100;
          setPriceChangePercentage(changePercentage.toFixed(2));
          setPriceTrend(currentPriceData.value > previousHourPriceData.value ? 'up' : (currentPriceData.value < previousHourPriceData.value ? 'down' : 'equal'));
          setNotificationMessage(`${currentPriceData.value} €/kWh (${changePercentage.toFixed(2)}%)`);
        } else {
          setPriceChangePercentage(null);
          setPriceTrend('');
          setNotificationMessage(`${currentPriceData.value} €/kWh`);
        }
  
        const nextBestPrice = findNextBestPrice(values, 0.15);
        console.debug('Next best price:', nextBestPrice);
  
        setNextBestPrice(nextBestPrice);
  
        if (currentPriceData.value <= 0.11) {
          setNotificationOpen(true);
        }
      } else {
        setCurrentPrice("N/A");
        setShowWarningScreen(true); // Mostrar pantalla de advertencia
        setPriceChangePercentage(null);
        setPriceTrend('');
        setNotificationMessage("N/A");
      }
    } catch (error) {
      let errorMessage = '';
  
      if (error.response) {
          switch (error.response.status) {
              case 400:
                  errorMessage += "Solicitud incorrecta. ¿Confundiste la URL con una de tus recetas? ¡Inténtalo de nuevo, detective! [400]";
                  break;
              case 401:
                  errorMessage += "Acceso no autorizado. Estamos experimentando un problema técnico. Por favor, inténtalo de nuevo más tarde. [Código de Error: 401]";
                  break;
              case 402:
                  errorMessage += "Pago requerido. La era dorada del acceso libre ha terminado. ¡Hora de abrir la billetera! [402]";
                  break;
              case 403:
                  errorMessage += "Prohibido. ¡Ups! Esa URL es un área restringida. No intentes cruzar el láser de seguridad. [403]";
                  break;
              case 404:
                  errorMessage += "Recurso no encontrado. Esa URL parece haber tomado un portal a otro universo. Busca en la puerta secreta. [404]";
                  break;
              case 405:
                  errorMessage += "Método no permitido. Intentaste una maniobra arriesgada que no está permitida. ¡Todo un rebelde! [405]";
                  break;
              case 406:
                  errorMessage += "No aceptable. Tu solicitud no tiene el nivel de sofisticación requerido. ¡Qué pretencioso! [406]";
                  break;
              case 407:
                  errorMessage += "Autenticación proxy requerida. Necesitas pasar primero por el filtro del proxy. ¡No olvides tu credencial! [407]";
                  break;
              case 408:
                  errorMessage += "Tiempo de espera agotado. El servidor está tomando una siesta. [408]";
                  break;
              case 409:
                  errorMessage += "Conflicto. Hay un pequeño lío aquí. ¿Has probado con yoga para solucionar conflictos? [409]";
                  break;
              case 410:
                  errorMessage += "Gone. Este recurso se ha ido, literalmente. ¡Adiós para siempre! [410]";
                  break;
              case 411:
                  errorMessage += "Longitud requerida. Necesitas especificar la longitud. ¡Los detalles importan! [411]";
                  break;
              case 412:
                  errorMessage += "Precondición fallida. No se cumplió una condición previa. Revisa tus pasos. [412]";
                  break;
              case 413:
                  errorMessage += "Payload demasiado grande. Tu carga es demasiado volumosa. ¡Un poco de moderación, por favor! [413]";
                  break;
              case 414:
                  errorMessage += "URI demasiado larga. Esa URL parece una serpiente enroscada. Simplifica un poco. [414]";
                  break;
              case 415:
                  errorMessage += "Tipo de medio no soportado. El servidor no entiende el formato de tus datos. ¡Tiempo de conversión! [415]";
                  break;
              case 416:
                  errorMessage += "Rango no satisfactorio. El rango solicitado está fuera de nuestro alcance. ¡Inténtalo de nuevo! [416]";
                  break;
              case 417:
                  errorMessage += "Expectativa fallida. Tus expectativas eran demasiado altas. ¡La vida es dura, amigo! [417]";
                  break;
              case 418:
                  errorMessage += "Soy una tetera. Sí, de verdad. El servidor se está tomando un descanso como tetera. No lo discutas. [418 🫖]";
                  break;
              case 421:
                  errorMessage += "Solicitud mal dirigida. Te has ido por la tangente. Regresa al camino correcto. [421]";
                  break;
              case 422:
                  errorMessage += "Entidad no procesable. Esto es más complicado que un rompecabezas de mil piezas. [422]";
                  break;
              case 423:
                  errorMessage += "Bloqueado. Este recurso está bajo llave. No tienes la clave mágica. [423]";
                  break;
              case 424:
                  errorMessage += "Dependencia fallida. Algo de lo que dependías se ha ido de vacaciones. ¡Efecto dominó! [424]";
                  break;
              case 425:
                  errorMessage += "Demasiado temprano. Aún no es el momento. ¡Necesitas más café antes de esto! [425]";
                  break;
              case 426:
                  errorMessage += "Actualización requerida. Necesitas actualizar para seguir. ¡Pasa a la última versión! [426]";
                  break;
              case 428:
                  errorMessage += "Precondición requerida. Primero necesitas cumplir ciertos requisitos. ¡Hazlo antes de que sea tarde! [428]";
                  break;
              case 429:
                  errorMessage += "Demasiadas solicitudes. ¡Tranquilo, Speedy Gonzales! No eres el único en la red. Espera 1 minuto. [429]";
                  break;
              case 431:
                  errorMessage += "Campos de cabecera demasiado grandes. Tus cabeceras tienen más detalles que una novela. [431]";
                  break;
              case 451:
                  errorMessage += "No disponible por razones legales. Este recurso está bloqueado por la ley. ¡Llama a tu abogado! [451]";
                  break;
              case 500:
                  errorMessage += "¡Vaya! El servidor ha encontrado un problema que le impide procesar tu solicitud en este momento. Nuestro equipo ya está trabajando para resolverlo. Por favor, inténtalo de nuevo más tarde. [Código de Error: 500]";
                  break;
              case 501:
                  errorMessage += "No implementado. Esto aún no está en funcionamiento. ¡Estamos aún inventando la rueda! [501]";
                  break;
              case 502:
                  errorMessage += "Mala puerta de enlace. El servidor está teniendo una mala conversación con otro servidor. [502]";
                  break;
              case 503:
                  errorMessage += "Servicio no disponible. El servidor está en su pausa para el café. Vuelve más tarde. [503]";
                  break;
              case 504:
                  errorMessage += "Tiempo de espera de la puerta de enlace. El servidor está esperando a otro. Ten paciencia. [504]";
                  break;
              case 505:
                  errorMessage += "Versión de HTTP no soportada. Tu versión de HTTP necesita una actualización. [505]";
                  break;
              case 506:
                  errorMessage += "Variante también negocia. El servidor está lidiando con problemas internos de negociación. [506]";
                  break;
              case 507:
                  errorMessage += "Almacenamiento insuficiente. El servidor está a tope. ¡Hora de hacer espacio! [507]";
                  break;
              case 508:
                  errorMessage += "Bucle detectado. El servidor está atrapado en un bucle infinito. ¡Sálvalo antes de que se maree! [508]";
                  break;
              case 510:
                  errorMessage += "No extendido. Necesitas una extensión para seguir adelante. ¡Pide una extensión! [510]";
                  break;
              case 511:
                  errorMessage += "Autenticación de red requerida. Necesitas autenticarte para acceder a esta red. [511]";
                  break;
              default:
                  errorMessage += `Algo salió mal en el servidor. Código de error: ${error.response.status}. ¡Un misterio por resolver!`;
          }
      } else if (error.request) {
          console.error(`No se recibió respuesta del servidor:`, error.request);
          if (error.message && error.message.includes("Network Error")) {
              errorMessage += "¡Vaya! Parece que tienes problemas para conectarte a la red. Por favor, revisa tu conexión a internet e inténtalo de nuevo. [Código de error: 0]";
          } else {
              errorMessage += "Error desconocido. Hasta el error se perdió. | Error: " + error.message;
          }
      } else {
          console.error(`Error en la configuración de la solicitud:`, error.message);
          errorMessage += "Error en la configuración de la solicitud: " + error.message + ". Verifica antes de que la magia desaparezca.";
      }
  
      // Establece el mensaje de error en el estado
      setError(errorMessage);
  } finally {
      setLoading(false);
  }
}, []);  

  

  useEffect(() => {
    const updateData = () => {
      fetchData(); // Actualizar los datos
    };
  
    const initialDelay = getMillisecondsUntilMidnight();
    const interval = 24 * 60 * 60 * 1000; // 24 horas en milisegundos
  
    // Ejecutar `fetchData` a medianoche
    const timerId = setTimeout(() => {
      updateData(); // Ejecutar actualización
      setInterval(updateData, interval); // Establecer intervalo de 24 horas
    }, initialDelay);
  
    return () => clearTimeout(timerId);
  }, [fetchData]);

  // Notification Snackbar close handler
  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotificationOpen(false);
    setNotificationTooHigh(false);

  };

  if (loading) {
    return (
      <div className="App">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div className="App">
<svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px" fill="#FF0000">
  <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
</svg>

        <Typography variant="h5" color="error" className='inter-tight-app'>
          <h3 className='inter-tight-app'>{error}</h3>
        </Typography>
        <br />
        <Button variant="contained" color="success" onClick={reloadPage}>
          Volver a intentar
        </Button>
        <br />
        <br />
        <Button variant="contained" color="warning" href='https://precionline.instatus.com/' target='_blank'>
          Página de estado
        </Button>
      </div>
    );
  }

  const minPrice = data.length > 0 ? data.reduce((min, p) => p.value < min ? p.value : min, data[0].value) : null;
  const maxPrice = data.length > 0 ? data.reduce((max, p) => p.value > max ? p.value : max, data[0].value) : null;
  const averagePrice = data.length > 0 ? (data.reduce((acc, item) => acc + item.value, 0) / data.length).toFixed(4) : null;
  const maxPriceHour = maxPrice ? data.find(item => item.value === maxPrice) : null;
  const { startHour, averagePrice: cheapestTwoHoursPrice } = data.length > 0 ? findCheapestTwoHours(data) : { startHour: null, averagePrice: null };
  

  const getPriceColor = (price) => {
    if (price <= 0.11) return 'green';
    if (price <= 0.165) return 'orange';
    if (price <= 0.22) return 'red';
    return 'purple';
  };

  const percentageOfRange = ((averagePrice / 0.3)) * 100;
  const getPriceBackground = (price) => {
    if (price <= 0.11) return 'green';
    if (price <= 0.165) return 'orange';
    if (price <= 0.22) return 'red';
    return 'purple';
  };

  const getPriceTrendIcon = () => {
    if (priceTrend === 'up') {
      return (
          <Tooltip title={`El precio ha subido respecto al anterior.  (${priceChangePercentage}%)`}>
          <FontAwesomeIcon icon={faArrowUp} style={{ color: 'white', marginLeft: '5px' }} />
        </Tooltip>
      );
    } else if (priceTrend === 'down') {
      return (
        <Tooltip title={`El precio ha bajado respecto al anterior.  (${priceChangePercentage}%)`}>
          <FontAwesomeIcon icon={faArrowDown} style={{ color: 'white', marginLeft: '5px' }} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="El precio se mantiene estable respecto al anterior.">
          <FontAwesomeIcon icon={faEquals} style={{ color: 'white', marginLeft: '5px' }} />
        </Tooltip>
      );
    }
  };

  const AnimatedPaper = ({ children, ...props }) => {
    const { ref, inView } = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });

    return (
      <CSSTransition
        in={inView}
        timeout={500}
        classNames="fade"
        nodeRef={ref}
      >
        <Paper ref={ref} {...props}>
          {children}
        </Paper>
      </CSSTransition>
    );
  };
  const isCurrentHourIdeal = currentHour >= startHour - 1 && currentHour < startHour + 2;

//**********************************************UIs*************************************************************/

if (showDisclaimer) {
  return (
    <div className="App disclaimer" style={{ textAlign: 'left', padding: '50px', backgroundColor: '#f8f9fa' }}>
      <Container>
        <h4  className="inter-tight-app" style={{textAlign: 'center' }}>Fecha de vigencia:: 28 de julio de 2024.</h4>
        <h1 className="inter-tight-app" style={{ fontSize: '36px', color: '#333', marginTop: '20px', textAlign: 'center' }}>
          Exención de Responsabilidad
        </h1>
        <Box sx={{ maxWidth: 900, margin: 'auto' }}>
          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            1. Introducción
          </h2>
          <p className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
            La presente Exención de Responsabilidad (en adelante, "Exención") establece los términos y condiciones bajo los cuales se permite el uso del presente sitio web (en adelante, "Sitio"). Al acceder y utilizar este Sitio, usted acepta y se compromete a cumplir con todos los términos y condiciones aquí establecidos. Si no está de acuerdo con alguna de las disposiciones contenidas en esta Exención, deberá cesar inmediatamente el uso del Sitio. Su uso continuado del Sitio después de la publicación de cualquier cambio en esta Exención constituirá su aceptación de dichos cambios.
          </p>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            2. Limitaciones de Responsabilidad sobre el Contenido del Sitio Web
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              2.1. El contenido disponible en este Sitio es proporcionado únicamente con fines informativos y educativos. No debe considerarse como asesoramiento profesional en ningún campo, incluyendo, pero no limitado a, asesoramiento médico, legal, financiero o técnico. Aunque nos esforzamos por garantizar que la información proporcionada sea precisa y actualizada, no hacemos representaciones ni garantías, explícitas o implícitas, sobre la integridad, exactitud, confiabilidad, adecuación o disponibilidad del contenido. Los usuarios del Sitio deben consultar a un profesional competente en el área relevante antes de tomar decisiones basadas en la información proporcionada en el Sitio.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              2.2. El uso que usted haga de la información contenida en este Sitio es estrictamente bajo su propio riesgo. No seremos responsables de ninguna pérdida o daño, incluyendo sin limitación, pérdida indirecta, incidental, especial, consecuente, o cualquier pérdida o daño que surja de la pérdida de datos o beneficios derivados del uso del Sitio. Esto incluye, pero no se limita a, daños causados por virus informáticos, errores, omisiones, interrupciones, retrasos en el funcionamiento o transmisión, o cualquier otro problema relacionado con el uso del Sitio.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            3. Enlaces a Sitios Web de Terceros
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              3.1. Este Sitio puede contener enlaces a sitios web de terceros que no están controlados ni gestionados por nosotros. Estos enlaces se proporcionan únicamente para su conveniencia y no implican aprobación, patrocinio, respaldo o recomendación del contenido de dichos sitios por parte de nosotros. No tenemos control sobre el contenido y la disponibilidad de estos sitios de terceros, y su inclusión en nuestro Sitio no implica que avalemos, garanticemos o aceptemos responsabilidad por cualquier información, productos o servicios ofrecidos en dichos sitios web.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              3.2. No asumimos ninguna responsabilidad por el contenido, la disponibilidad, la exactitud, la calidad o las prácticas de privacidad de los sitios web de terceros. Le recomendamos que lea las políticas de privacidad y los términos de uso de cualquier sitio web de terceros antes de proporcionarles cualquier información personal o utilizar sus servicios. La inclusión de enlaces no debe interpretarse como una recomendación o una garantía de los sitios web enlazados.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            4. Disponibilidad del Sitio Web y Funcionamiento Técnico
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              4.1. Nos esforzamos por mantener el Sitio en funcionamiento óptimo y accesible en todo momento. Sin embargo, no garantizamos que el Sitio esté disponible de manera continua o libre de errores técnicos. El Sitio puede experimentar interrupciones, fallos técnicos o problemas de disponibilidad debido a mantenimiento, actualizaciones, fallos del servidor, o problemas ajenos a nuestro control. Nos reservamos el derecho de suspender temporal o permanentemente el acceso al Sitio, sin previo aviso, por razones técnicas, de mantenimiento o por cualquier otro motivo que consideremos necesario.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              4.2. No seremos responsables por ninguna interrupción, demora o mal funcionamiento del Sitio causado por problemas técnicos, fallos de hardware o software, mantenimiento rutinario, actualizaciones, ataques cibernéticos, o cualquier otro evento fuera de nuestro control razonable. Esto incluye, pero no se limita a, problemas relacionados con el acceso a internet, proveedores de servicios, o problemas en la infraestructura de telecomunicaciones.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            5. Exclusión de Responsabilidad por Daños
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              5.1. En la máxima medida permitida por la ley aplicable, excluimos toda responsabilidad por cualquier daño o pérdida que pueda surgir del uso o la incapacidad para usar el Sitio, incluyendo, pero no limitado a, daños directos, indirectos, incidentales, consecuentes, punitivos, así como pérdida de datos, ingresos, beneficios, reputación, o cualquier otra pérdida intangible. Esta exclusión se aplica independientemente de la causa de acción, ya sea por incumplimiento del contrato, negligencia, difamación, error u omisión, interrupción del servicio, virus informático, acceso no autorizado, alteración de registros u otras acciones que puedan causar daño.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              5.2. Nos reservamos el derecho a modificar o eliminar cualquier contenido del Sitio en cualquier momento sin previo aviso. La eliminación o modificación del contenido no constituirá una violación de esta Exención y no asumimos ninguna responsabilidad por cualquier pérdida o daño que pueda resultar de tales acciones.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            6. Indemnización
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              6.1. Usted acepta indemnizar, defender y mantener indemne a nuestro Sitio, así como a nuestros empleados, directores, agentes, afiliados, socios, y proveedores de servicios, de y contra cualquier reclamación, pérdida, daño, costo, responsabilidad y gasto (incluidos, entre otros, honorarios legales) que surjan de o estén relacionados con su uso del Sitio, el incumplimiento de estos términos y condiciones, o cualquier violación de los derechos de terceros. Esta indemnización se aplicará en la medida máxima permitida por la ley aplicable.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              6.2. La indemnización aquí descrita incluye cualquier reclamación relacionada con el contenido que usted envíe al Sitio, incluyendo, pero no limitado a, cualquier reclamación de infracción de propiedad intelectual, difamación, o violación de los derechos de privacidad o publicidad.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            7. Cambios a Esta Exención de Responsabilidad
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              7.1. Nos reservamos el derecho de modificar o actualizar esta Exención en cualquier momento, sin previo aviso. Las modificaciones serán efectivas inmediatamente después de su publicación en el Sitio. Es su responsabilidad revisar periódicamente esta página para mantenerse informado sobre cualquier cambio. El uso continuado del Sitio después de la publicación de cambios constituirá su aceptación de dichos cambios.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              7.2. Si realiza alguna modificación, se indicará la fecha de la última actualización en la parte superior de esta Exención. Le recomendamos que revise esta página periódicamente para mantenerse informado sobre cualquier cambio.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            8. Ley Aplicable y Jurisdicción
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              8.1. Esta Exención se regirá e interpretará de acuerdo con las leyes del país en el que se encuentra nuestra sede principal, sin dar efecto a ningún principio de conflictos de leyes. Cualquier disputa que surja en relación con esta Exención estará sujeta a la jurisdicción exclusiva de los tribunales de dicha jurisdicción.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              8.2. Usted acepta someterse a la jurisdicción personal de los tribunales ubicados en el país donde se encuentra nuestra sede principal para la resolución de cualquier disputa relacionada con esta Exención.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            9. Divisibilidad
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              9.1. Si alguna disposición de esta Exención se considera inválida, ilegal o inaplicable por un tribunal competente, dicha disposición se eliminará o limitará en la medida mínima necesaria para que las disposiciones restantes de la Exención continúen en pleno vigor y efecto. La invalidez de cualquier disposición no afectará la validez y aplicabilidad de las disposiciones restantes.
            </li>
          </ul>

          <h2 className="inter-tight-app" style={{ fontSize: '28px', color: '#333', marginTop: '20px' }}>
            10. Derechos de Propiedad Intelectual
          </h2>
          <ul>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              10.1. Todos los derechos de propiedad intelectual, incluidos, entre otros, derechos de autor, marcas registradas, patentes, secretos comerciales y otros derechos de propiedad relacionados con el contenido del Sitio, son propiedad de nosotros o de nuestros licenciantes. Usted <strong>NO</strong> tiene derecho a usar, copiar, modificar, distribuir, vender, licenciar o explotar de otra manera cualquier contenido del Sitio sin el consentimiento previo por escrito de los respectivos propietarios.
            </li>
            <li className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
              10.2. El uso no autorizado del contenido del Sitio puede violar las leyes de propiedad intelectual y otras leyes aplicables y puede resultar en sanciones civiles y penales. Nos reservamos el derecho de hacer valer vigorosamente nuestros derechos de propiedad intelectual en la máxima medida permitida por la ley.
            </li>
          </ul>

          <Button variant="contained" onClick={toggleDisclaimer}>
            Volver
          </Button>
        </Box>
      </Container>
    </div>
  );
}


if (showMaintenanceNotice) {
  return (
    <div className="App maintenance-notice" style={{ textAlign: 'center', padding: '50px', backgroundColor: '' }}>
      <Container>
        <Box sx={{ maxWidth: 600, margin: 'auto' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 105.01 122.88"
            style={{ width: '100px', height: '100px', fill: '#007bff' }}
          >
            <title>maintenance</title>
            <path
              className="cls-1"
              style={{ fillRule: 'evenodd' }}
              d="M.59,97C-.88,102.74.45,109.21,4,114.77c4.54-23.64,32.37-13.59,18.08,8.11,12.37-1,19.05-10.55,18.79-22.31-.12-5.54,0-8.55.87-11.07a13.16,13.16,0,0,1,1.67-3.21,11.5,11.5,0,0,1-.91-4.42v-.29a11.73,11.73,0,0,1,1.2-5l-7.43-7.23-2.06,2.73a53.12,53.12,0,0,1-6.66,6.63,18.68,18.68,0,0,1-4.59,2.07C13,84.19,3.65,85.11.59,97Zm58.16-21-44-42.84-6-1.06a3.65,3.65,0,0,1-1.9-1l-5.11-5a3.65,3.65,0,0,1-.07-5.15l6.17-6.23a3.64,3.64,0,0,1,5.14,0l4.53,4.49a3.58,3.58,0,0,1,1,1.83l2,7.31L64,70.79l4.43-4.46a.7.7,0,0,1,1,0l2.89,2.86a.7.7,0,0,1,.21.45c.12.89.19,1.61.26,2.22.15,1.49.23,2.24.5,2.5s1,.3,2.45.4h0c.62,0,1.37.1,2.37.2a.67.67,0,0,1,.43.2l22.22,22.4A11.49,11.49,0,0,1,104.44,110a11.87,11.87,0,0,1-2.59,4.42,11,11,0,0,1-4.22,2.85c-3.75,1.39-8.44.66-12.87-3.81L63.53,92a.76.76,0,0,1-.21-.44c-.18-1.21-.29-2.19-.38-3-.19-1.61-.29-2.45-.61-2.76s-1.26-.37-3.14-.43c-.65,0-1.41,0-2.3-.09a.7.7,0,0,1-.46-.2l-2.84-2.81a.71.71,0,0,1,0-1l5.17-5.22ZM70.29,90.64a1.76,1.76,0,0,1,0-2.5,1.78,1.78,0,0,1,2.51,0L92,107.6a1.77,1.77,0,0,1-2.5,2.52L70.29,90.64Zm5.28-5.34a1.77,1.77,0,0,1,2.49-2.52l19.25,19.48a1.77,1.77,0,1,1-2.49,2.52L75.57,85.3ZM67,55.36l5.94-7.77c7.15-9.71,25.79-4,30.36-21.75,1.46-5.7.13-12.16-3.39-17.73C95.37,31.75,67.55,21.7,81.84,0,69.47,1,62.79,10.55,63.05,22.31c.12,5.16,1.57,9.37-3.32,15.91L54,45.83,64.5,56.06a11.47,11.47,0,0,1,2.5-.7Z"
            />
          </svg>
          <h1 className="inter-tight-app">Sitio en Mantenimiento</h1>
          <p className="inter-tight-app" style={{ fontSize: '18px', color: '#555' }}>
            En este momento estamos realizando tareas de mantenimiento en nuestro sitio web.
          </p>
          <p className="inter-tight-app" style={{ fontSize: '16px', color: '#333' }}>
            Duración estimada: {formatTime(timeRemaining)}
          </p>
          <p className="inter-tight-app" style={{ fontSize: '16px', color: '#333' }}>
            Motivo: Mejora y optimización del sitio web.
          </p>
          <p className="inter-tight-app" style={{ fontSize: '16px', color: '#333' }}>
            Para más información, por favor visita nuestro <a href="https://precionline.instatus.com/" target="_blank" rel="noopener noreferrer" style={{ color: '#007bff' }}>sitio de estado</a>.
          </p>
        </Box>
      </Container>
    </div>
  );
}
if (showLineChart) {
  return (
    <div className={`chart-wrapper ${isPortrait ? 'portrait' : 'landscape'}`}>
      {loading && <p>Cargando datos...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && (
        <div className="chart-container">
          <LineChart data={data} />
        </div>
      )}
      <div className="button-container">
        <Button variant="contained" onClick={toggleLineChart}>
          Volver
        </Button>
      </div>
      {isPortrait && (
          <p className='inter-tight-app'>Recomendable colocar el dispositivo en una posición horizontal</p>
      )}
    </div>
  );
}
if (showLegalNotice) {
  return (
      <div className="App legal-notice">
          <Container>
              <h1 className='inter-tight-app' variant="h4" gutterBottom>
                  Términos de Uso
              </h1>

              <Box textAlign="left" sx={{ maxWidth: 900, margin: 'auto' }}>
                  
                  <h2 className='inter-tight-app' variant="h6" gutterBottom>
                      1. Datos del Responsable
                  </h2>
                  <ul>
                      <li>Responsable del sitio: Administrador de Precionline.com</li>
                      <li>Email de contacto: admin@precionline.com</li>
                  </ul>

                  <h2 className='inter-tight-app' variant="h6" gutterBottom>
                      2. Propiedad Intelectual
                  </h2>
                  <p className='inter-tight-app'>
                      Todos los contenidos presentes en este sitio web, incluyendo, sin limitarse a, textos, imágenes, diseño gráfico, código fuente, logotipos, marcas y otros elementos, están protegidos por las leyes vigentes en materia de propiedad intelectual e industrial.
                      <br />
                      Queda estrictamente prohibido cualquier acto de reproducción, distribución, modificación o comunicación pública de estos contenidos sin la autorización expresa y por escrito del titular de los derechos.
                  </p>

                  <h3 className='inter-tight-app' variant="h6" gutterBottom>
                      2.1. Uso del Contenido
                  </h3>
                  <ul>
                      <li>El uso no autorizado de los contenidos de este sitio web puede resultar en acciones legales, incluidas sanciones civiles y/o penales.</li>
                      <li>Se permite el uso de los contenidos únicamente para fines personales y no comerciales, siempre y cuando se respeten los derechos de autor y propiedad intelectual.</li>
                      <li>Cualquier solicitud para el uso de contenidos fuera de estas limitaciones debe dirigirse a través del email de contacto.</li>
                  </ul>

                  <h2 className='inter-tight-app' variant="h6" gutterBottom>
                      3. Responsabilidad de los Contenidos
                  </h2>
                  <p className='inter-tight-app'>
                      Los contenidos de este sitio web son de carácter informativo y general. Aunque se realiza un esfuerzo por mantener la información actualizada y precisa, no se garantiza la exactitud, integridad o actualidad de los contenidos.
                      <br />
                      El administrador de Precionline.com no se responsabiliza por el uso indebido que los usuarios puedan hacer de la información presentada en el sitio.
                  </p>

                  <h3 className='inter-tight-app' variant="h6" gutterBottom>
                      3.1. Limitación de Responsabilidad
                  </h3>
                  <ul>
                      <li>No se asume responsabilidad por los daños y perjuicios derivados del uso o la imposibilidad de uso de la información en este sitio web.</li>
                      <li>El acceso y uso del sitio es responsabilidad exclusiva del usuario.</li>
                      <li>No se garantiza la disponibilidad continua del sitio web, ni se asume responsabilidad por fallos técnicos, interrupciones del servicio o virus informáticos.</li>
                  </ul>

                  <h2 className='inter-tight-app' variant="h6" gutterBottom>
                      4. Enlaces a Sitios de Terceros
                  </h2>
                  <p className='inter-tight-app'>
                      Este sitio web puede contener enlaces a sitios web operados por terceros. Estos enlaces se proporcionan únicamente con fines informativos. 
                      <br />
                      Precionline.com no tiene control sobre el contenido de dichos sitios y no asume responsabilidad por cualquier daño o perjuicio derivado del acceso a ellos o del uso de la información y servicios proporcionados por esos terceros.
                  </p>

                  <h3 className='inter-tight-app' variant="h6" gutterBottom>
                      4.1. Política de Enlaces
                  </h3>
                  <ul>
                      <li>El administrador de Precionline.com no garantiza la veracidad o calidad de los contenidos ofrecidos por sitios web de terceros enlazados desde esta página.</li>
                      <li>Se recomienda a los usuarios que revisen los términos y políticas de privacidad de los sitios enlazados.</li>
                      <li>La inclusión de un enlace a un sitio de terceros no implica una aprobación o respaldo por parte de Precionline.com.</li>
                  </ul>

                  <h2 className='inter-tight-app' variant="h6" gutterBottom>
    5. Protección de Datos
</h2>
<p className='inter-tight-app'>
    Este sitio web no recoge datos personales sin el conocimiento y consentimiento explícito del usuario, salvo en los casos de datos técnicos necesarios para la correcta funcionalidad del sitio. Se recogen automáticamente ciertos datos como la dirección IP, la ciudad, la región, el país y el proveedor de Internet. Estos datos son necesarios para el funcionamiento, análisis de seguridad y mejora del servicio.
    <br />
    En el caso de recogerse otros datos personales, se informará claramente al usuario de la finalidad de su uso y se solicitará el consentimiento correspondiente.
    <br />
    Los datos personales no se compartirán con terceros, excepto cuando exista una obligación legal de hacerlo.
</p>

<h3 className='inter-tight-app' variant="h6" gutterBottom>
    5.1. Política de Privacidad
</h3>
<ul>
    <li>Se garantiza la confidencialidad y seguridad de los datos personales recogidos.</li>
    <li>El usuario tiene derecho a acceder, rectificar y solicitar la eliminación de sus datos personales en cualquier momento.</li>
    <li>Cualquier solicitud relacionada con la protección de datos puede enviarse al email de contacto (admin@precionline.com).</li>
</ul>

                  <h2 className='inter-tight-app' variant="h6" gutterBottom>
                      6. Legislación y Jurisdicción Aplicable
                  </h2>
                  <p className='inter-tight-app'>
                      Las presentes condiciones de uso se rigen por la legislación española. Para cualquier controversia que pudiera surgir en relación con el uso de este sitio web, las partes se someten a la jurisdicción de los tribunales y juzgados del domicilio del usuario o del administrador de Precionline.com, a elección de este último.
                  </p>
                  <ul>
                      <li>En caso de conflicto, se aplicará la legislación española.</li>
                      <li>Las partes acuerdan someterse a los tribunales competentes según la jurisdicción aplicable.</li>
                  </ul>

              </Box>

              <Button variant="contained" onClick={toggleLegalNotice}>
                  Volver
              </Button>
          </Container>
      </div>
  );
}
if (showCookiePolicy) {
  return (
    <div className="App cookies-policy">
      <Container>
        <h1 className='inter-tight-app' variant="h4" gutterBottom>
          Política de Cookies
        </h1>

        <Box textAlign="left" sx={{ maxWidth: 900, margin: 'auto' }}>
          <h2 className='inter-tight-app' variant="h6" gutterBottom>
            1. ¿Qué son las Cookies?
          </h2>
          <p className='inter-tight-app'>
            Las cookies son pequeños archivos de texto que se almacenan en su dispositivo (ordenador, smartphone, tablet) cuando visita un sitio web. Estas cookies permiten al sitio web recordar sus acciones y preferencias durante un período de tiempo, facilitando su navegación en futuras visitas.
          </p>
          <br />

          <h2 className='inter-tight-app' variant="h6" gutterBottom>
            2. Cookies que Utilizamos
          </h2>
          <p className='inter-tight-app'>
            En nuestro sitio web, utilizamos la siguiente cookie:
          </p>
          <h3 className='inter-tight-app' variant="h6" gutterBottom>
            Cookie de Nombre: www.precionline.com
          </h3>
          <p className='inter-tight-app'>
            <strong>Nombre:</strong> www.precionline.com
          </p>
          <p className='inter-tight-app'>
            <strong>Propósito:</strong> Esta cookie se utiliza para almacenar preferencias del usuario y mejorar la experiencia de navegación en el sitio web.
          </p>
          <p className='inter-tight-app'>
            <strong>Duración:</strong> La cookie tiene una duración específica que puede variar dependiendo de la configuración del navegador del usuario.
          </p>
          <p className='inter-tight-app'>
            <strong>Tipo:</strong> Cookie técnica
          </p>
          <br />

          <h2 className='inter-tight-app' variant="h6" gutterBottom>
            3. Cómo Gestionar las Cookies
          </h2>
          <p className='inter-tight-app'>
            Usted puede aceptar o rechazar cookies modificando la configuración de su navegador. La mayoría de los navegadores permiten gestionar las cookies a través de sus opciones de configuración. A continuación, se proporcionan enlaces a guías para gestionar cookies en los navegadores más comunes:
          </p>
          <ul className='inter-tight-app'>
            <li>
              <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noopener noreferrer">Google Chrome</a>
            </li>
            <li>
              <a href="https://support.mozilla.org/es/kb/activar-desactivar-cookies" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a>
            </li>
            <li>
              <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noopener noreferrer">Apple Safari</a>
            </li>
            <li>
              <a href="https://support.microsoft.com/es-es/help/4468242/windows-10-view-delete-browsing-history" target="_blank" rel="noopener noreferrer">Microsoft Edge</a>
            </li>
          </ul>
          <p className='inter-tight-app'>
            Si decide bloquear todas las cookies, algunas funcionalidades del sitio web pueden no estar disponibles o no funcionar correctamente.
          </p>
          <br />

          <h2 className='inter-tight-app' variant="h6" gutterBottom>
            4. Actualizaciones de la Política de Cookies
          </h2>
          <p className='inter-tight-app'>
            La presente Política de Cookies puede ser modificada para adaptarse a cambios legislativos, jurisprudenciales o de interpretación. Le recomendamos que revise esta política periódicamente para estar al tanto de cualquier cambio.
          </p>
          <br />

          <h2 className='inter-tight-app' variant="h6" gutterBottom>
            5. Contacto
          </h2>
          <p className='inter-tight-app'>
            Si tiene preguntas sobre nuestra Política de Cookies, puede contactarnos a través de:
          </p>
          <ul className='inter-tight-app'>
            <li>
              <strong>Email:</strong> admin@precionline.com
            </li>
          </ul>
        </Box>

        <Button variant="contained" onClick={toggleCookiePolicy}>
          Volver
        </Button>
      </Container>
    </div>
  );
}
  return (
    
    <Container className="App">
    {selectedDate.toDateString() !== today && (
      <div className="date-warning">
        <Typography variant="h6" color="textSecondary">
          Estás viendo datos del día {formatDate(selectedDate)}.
        </Typography>
        <Button color="primary" variant="contained" onClick={reloadPage}>
          Volver al día actual
        </Button>
      </div>
    )}
    {/* Resto del código */}

<Container className="App">
    {showWarningScreen && (
    <div className="warning-screen">
      <div className="warning-message">
        <FontAwesomeIcon icon={faCircleExclamation} className="warning-icon" />
        <div className="warning-text">
          <h1 className='inter-tight-app'><strong>Atención:</strong></h1>
          <h3 className='inter-tight-app'>Los datos solicitados no estarán disponibles hasta las <strong>20:30 horas </strong>aproximadamente.</h3>
          <h3 className='inter-tight-app'> Esto se debe a que la actualización de los datos se realiza automáticamente sobre esa hora. </h3>
          <h3 className='inter-tight-app'>Si desea ver la información más reciente, le recomendamos intentar nuevamente después de esa hora. 
          Mientras tanto, puede consultar la información del <strong>día actual</strong> si lo necesita.</h3>
        </div>
        <Button color="error" variant="contained" onClick={reloadPage}>
          Volver al día actual
        </Button>
      </div>
    </div>
  )}
</Container>


      <h2 variant="h3" gutterBottom className="inter-tight-app gradient-text">
        <FontAwesomeIcon icon={faLightbulb} style={{ color: 'orange' }} /> Precio de la Luz Hoy en España - PreciOnline <FontAwesomeIcon icon={faLightbulb} style={{ color: 'orange' }} />
      </h2>
    {/* Botón para mostrar/ocultar el calendario */}
       <Button variant="contained" onClick={toggleCalendar} style={{ marginBottom: '20px' }}>
  {showCalendar ? <FontAwesomeIcon icon={faCalendar} /> : <FontAwesomeIcon icon={faCalendar} />}
</Button>

{showCalendar && (
  <div className="calendar-container" style={{ position: 'relative', zIndex: 10 }}>
    <Calendar
      onChange={setSelectedDate}
      value={selectedDate}
      onClickDay={(value, event) => {
        console.log("Day clicked:", value);
        handleCalendarDateSelect(value, event);
      }}
      minDate={new Date(new Date().setDate(new Date().getDate() - 30))} // Muestra solo las últimas 2 semanas
      maxDate={new Date(new Date().setDate(new Date().getDate() + 1))} // Muestra hasta el día actual
    />
  </div>
)}


    {/* Snackbar para mostrar la notificación */}
    <Snackbar
      open={showNotification}
      autoHideDuration={5000}
      onClose={handleNotificationClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleNotificationClose} severity="info" sx={{ width: '100%' }}>
        {notificationMessage}
      </Alert>
    </Snackbar>
    <Grid container spacing={3}>
      
  {/* Condicional para mostrar solo si la fecha seleccionada es el día actual */}
  {selectedDate.toDateString() === new Date().toDateString() && (
    <>
      <Grid item xs={12}>
      <div>
      <h3 className='inter-tight-app-manana'>
      <strong>
        <a href="#" onClick={goToNextDay}>
          Precios de mañana
        </a>
        <span className='inter-tight-app'>
          {isAfterEightThirtyFivePM ? ' 🟢' : ' ❌'}
        </span>
      </strong>
</h3>

    </div>
    <AnimatedPaper 
  className="Paper" 
  style={{ 
    backgroundColor: getPriceBackground(currentPrice), 
    padding: '30px', 
    borderRadius: '12px',
    textAlign: 'center',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
    transform: 'none',  // Eliminado el efecto de escala
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',  // Eliminado el efecto de sombra en hover
  }}
>
  <h1 
    className="inter-tight-app" 
    style={{ 
      color: 'white', 
      fontSize: '2rem', 
      marginBottom: '15px',
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', 
    }}
  >
    Precio actual: <br />
    <strong style={{ 
      fontSize: '2.5rem', 
      color: '#', 
    }}>
      {currentPrice ? `${currentPrice} €/kWh` : "N/A"}
    </strong> 
    {priceTrend && (
      <span style={{ 
        marginLeft: '10px', 
      }}>
        {getPriceTrendIcon()}
      </span>
    )}
  </h1>
  <h4 
    style={{ 
      color: 'white', 
      fontSize: '1rem', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
    }}
  >
    Actualizado el: {new Date().toLocaleString([], { hour12: false })}
    <IconButton 
      onClick={reloadPage} 
      style={{ 
        color: 'white', 
        marginLeft: '12px', 
      }}
    >
      <RefreshIcon />
    </IconButton>
  </h4>
</AnimatedPaper>
      </Grid>
      <Grid item xs={12}>
        <AnimatedPaper className="Paper" style={{ backgroundColor: nextBestPrice.hour === null ? 'black' : getPriceBackground(nextBestPrice.price) }}>
          {nextBestPrice.hour !== null ? (
            <>
              <h1 style={{ color: 'white' }}>
                Siguiente mejor precio: {nextBestPrice.hour}:00
              </h1>
              <h2 style={{ color: 'white' }}>
                {nextBestPrice.price} €/kWh
              </h2>
            </>
          ) : (
            <h1 style={{ color: 'white' }}>
              No hay una siguiente mejor hora
            </h1>
          )}
        </AnimatedPaper>
      </Grid>
    </>
  )}
<Grid item xs={12}>

        <AnimatedPaper className="Paper" style={{ backgroundColor: isCurrentHourIdeal ? '#ADD8E6' : 'inherit' }}>
          <h1 variant="h4" className="inter-tight-app">
            <strong> <Tooltip title={`Este es el periodo de horas más baratas de 7:00 a 22:00. El color de fondo cambiará cuando las horas sean más baratas.`}>
        <FontAwesomeIcon icon={faClock}  style={{ color: 'blue' }} /> </Tooltip> EcoHoras</strong>
          </h1>
          <h1 variant="h4" color="primary" className="inter-tight-app">
            <strong>{startHour !== null ? `${startHour - 1}:00 - ${startHour + 2}:00` : "N/A"}</strong>
          </h1>
          <p>
            <strong><FontAwesomeIcon icon={faArrowDown} /> {cheapestTwoHoursPrice ? `${cheapestTwoHoursPrice.toFixed(4)} €/kWh` : "N/A"}</strong>
          </p>
        </AnimatedPaper>
      </Grid>
      {/* Aviso de cookies */}
      <CookieNotice open={showCookieNotice} onClose={handleCloseCookieNotice} />
      <Grid item xs={12} md={4}>
        <AnimatedPaper className="Paper">
          <h2 variant="h6" className="inter-tight-app">
          <FontAwesomeIcon icon={faArrowDown} style={{ color: 'green' }} /> Precio más bajo del día
          </h2>
          <h1 className="inter-tight-app">
            <strong>{minPrice ? `${minPrice.toFixed(4)} €/kWh` : "N/A"}</strong>
          </h1>
          {minPrice && (
            <h3 variant="h6">
              {new Date(data.find(item => item.value === minPrice).datetime).getHours()}:00 - {new Date(data.find(item => item.value === minPrice).datetime).getHours()}:59
            </h3>
          )}
        </AnimatedPaper>
      </Grid>
      <Grid item xs={12} md={4}>
        <AnimatedPaper className="Paper">
          <h2 variant="h6" className="inter-tight-app">
          <FontAwesomeIcon icon={faChartLine} /> Precio medio del día
          </h2>
          <h1 className="inter-tight-app">
            <strong>{averagePrice ? `${averagePrice} €/kWh` : "N/A"}</strong>
          </h1>
          {averagePrice && (
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{
                  width: `${percentageOfRange}%`,
                  backgroundColor: getProgressBarColor(percentageOfRange)
                }}
              />
              <div className="percentage-text">{percentageOfRange.toFixed(0)}%</div>
            </div>
          )}
        </AnimatedPaper>
      </Grid>

      <Grid item xs={12} md={4}>
        <AnimatedPaper className="Paper">
          <h2 variant="h6" className="inter-tight-app">
          <FontAwesomeIcon icon={faArrowUp} style={{ color: 'red' }} /> Precio más alto del día
          </h2>
          <h1 className="inter-tight-app">
            <strong>{maxPrice ? `${maxPrice.toFixed(4)} €/kWh` : "N/A"}</strong>
          </h1>
          {maxPrice && (
            <h3 variant="h6">
              {new Date(maxPriceHour.datetime).getHours()}:00 - {new Date(maxPriceHour.datetime).getHours()}:59
            </h3>
          )}
        </AnimatedPaper>
      </Grid>
    </Grid>
      <Grid>
      <Grid item xs={2} md={5}>
  <h2 className="inter-tight-app">
    Precio del kWh de luz por hora
  </h2>
  <Button type="button" variant="contained" color="primary" onClick={toggleLineChart}>
          Ver gráfico
  </Button>
  <div>
  {/* Tabla de precios */}
  <table className="price-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
    <thead>
      <tr style={{ backgroundColor: '#f2f2f2', fontSize: '1.1em' }}>
        <th className="inter-tight-app" style={{ padding: '5px', borderBottom: '2px solid #ddd' }}>Hora</th>
        <th className="inter-tight-app" style={{ padding: '5px', borderBottom: '2px solid #ddd' }}>Precio (€/kWh)</th>
      </tr>
    </thead>
    <tbody className="App">
      {Array.from({ length: 24 }, (_, hour) => {
        const currentHour = new Date().getHours();
        const hourData = data.find(item => {
          const itemDate = new Date(item.datetime);
          const itemLocalHour = itemDate.toLocaleString('en-US', { hour: '2-digit', hour12: false });
          return parseInt(itemLocalHour) === hour;
        });

        const previousHourData = data.find(item => {
          const itemDate = new Date(item.datetime);
          const itemLocalHour = itemDate.toLocaleString('en-US', { hour: '2-digit', hour12: false });
          return parseInt(itemLocalHour) === (hour === 0 ? 23 : hour - 1);
        });

        const isCurrentHour = hour === currentHour;
        const percentage = hourData ? (hourData.value / 0.3) * 100 : 0;
        const rowClass = isCurrentHour ? 'current-hour-row' : '';
        const priceVariation = hour === 0
          ? 0
          : (hourData && previousHourData
            ? ((hourData.value - previousHourData.value) / previousHourData.value) * 100
            : null);

        const variationColor = priceVariation === null
          ? 'gray'
          : priceVariation > 0
            ? 'red'
            : 'blue';

        // Alerta de precio bajo (menor de 0.05 €/kWh)
        const isLowPrice = hourData && hourData.value < 0.05;

        return (
          <tr
            key={hour}
            className={rowClass}
            style={{
              backgroundColor: hourData && hourData.value > 0.26
                ? 'rgba(255, 0, 0, 0.2)'
                : (isLowPrice ? 'rgba(0, 255, 0, 0.2)' : (isCurrentHour ? '#ffeb3b' : '#fff')),
              fontSize: '1em',
              borderBottom: '1px solid #ddd'
            }}
          >
            <td data-label="Hora" style={{ padding: '5px', textAlign: 'center', position: 'relative' }} className='inter-tight-app'>
              {isCurrentHour ? (
                <strong>
                  {`${hour}:00 - `}<span style={{ fontSize: '0.8em' }}>{`${hour}:59`}</span>
                </strong>
              ) : (
                <>
                  {`${hour}:00 - `}<span style={{ fontSize: '0.8em' }}>{`${hour}:59`}</span>
                </>
              )}
              {hourData && hourData.value > 0.26 && (
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '54%',
                    transform: 'translateY(-50%)',
                    fontSize: '1.5em',
                    color: 'red'
                  }}
                >
                  <FontAwesomeIcon icon={faCircleExclamation} className="warning" />
                </span>
              )}
              {isLowPrice && (
                <span
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '54%',
                    transform: 'translateY(-50%)',
                    fontSize: '1.5em',
                    color: 'green'
                  }}
                >
                  <FontAwesomeIcon icon={faCircleCheck} className="low-price" />
                </span>
              )}
            </td>
            <td data-label="Precio (€/kWh)" className="price-cell" style={{ padding: '5px', textAlign: 'center' }}>
              <div className="price-info" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span className="inter-tight-app" style={{ fontSize: '1.1em', marginBottom: '3px' }}>
                  {hourData ? `${hourData.value.toFixed(4)} €/kWh` : "N/A"}
                </span>
                <div className="price-bar-container" style={{ width: '100%', backgroundColor: '#f0f0f0', borderRadius: '3px', overflow: 'hidden', height: '8px' }}>
                  <div
                    className="price-bar"
                    style={{
                      width: `${percentage}%`,
                      backgroundColor: hourData ? getPriceColor(hourData.value) : 'gray',
                      height: '100%'
                    }}
                  />
                </div>
                <div className="percentage-text" style={{ marginTop: '3px' }}>
                  <span
                    style={{
                      color: variationColor,
                      fontWeight: 'bold',
                      fontSize: '1em'
                    }}
                  >
                    {priceVariation !== null
                      ? `${priceVariation.toFixed(0)}%`
                      : "N/A"}
                  </span>
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
  <br />
  <a href='#' onClick={downloadCSV} className='inter-tight-app'>Descargar CSV</a>
</div>
</Grid>
    </Grid>
    <Container className="App">
  <br />
  <div className="App">
      {showFeedbackBox && (
        <div className={`feedback-box ${fadeOut ? "fade-out" : ""}`}>
          {feedback === null ? (
            <>
              <h2 className="inter-tight-app">¿Te ha sido útil la página?</h2>
              <div className="buttons">
                <button className="btn-yes" onClick={() => handleFeedback("Sí")}>
                  Sí
                </button>
                <button className="btn-no" onClick={() => handleFeedback("No")}>
                  No
                </button>
              </div>
            </>
          ) : feedback === "Sí" ? (
            <div className="epic-thank-you">
              <div className="heart">💖</div>
              <h2 className="inter-tight-app">¡Gracias por apoyarnos!</h2>
              <a href="https://www.patreon.com/PreciOnline" target="_blank" rel="noopener noreferrer" className="donate-button">
                ¡Dónanos aquí!
              </a>
            </div>
          ) : (
            <div className="improve-message">
              <p className="inter-tight-app">Lamentamos mucho escuchar eso. Nos esforzamos cada día por mejorar, pero parece que no hemos logrado lo que esperábamos. ¿Qué hicimos mal? Queremos corregirlo.</p>
              <textarea
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Deja tu comentario aquí..."
                className="comment-box"
                disabled={isSubmitting}
              />
              <button className="submit-btn" onClick={handleSubmit2} disabled={isSubmitting}>
                {isSubmitting ? "Enviando..." : "Enviar comentario"}
              </button>
              <button className="submit-btn" onClick={handleRetry} disabled={isSubmitting}>
                Cambiar mi respuesta
              </button>
            </div>
          )}
        </div>
      )}
    </div>
    <h1 className='gradient-text-electrohora2'>ElectroHora</h1>
    <h2 className='gradient-text-electrohora1'>Encuentra la mejor hora para usar tus electrodomésticos</h2>
    <form onSubmit={handleSubmit}>
     {/* <TextField
        select
        label="Electrodoméstico"
        value={appliance}
        onChange={handleApplianceChange}
        fullWidth
        margin="normal"
      >
        {appliancesList.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>*/}
      <TextField
        label="Duración (horas)"
        type="number"
        value={duration}
        onChange={handleDurationChange}
        fullWidth
        margin="normal"
        helperText="*El tiempo aproximado que el electrodoméstico estará encendido."
      />
      <TextField
        label="Consumo (W)"
        type="number"
        value={power}
        onChange={handlePowerChange}
        fullWidth
        margin="normal"
        helperText="*El consumo en vatios (W) debe aparecer en la etiqueta del electrodoméstico o en su manual de usuario."
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Calcular mejor hora
      </Button>
    </form>
    {bestTime && (
      <Paper style={{ marginTop: '20px', padding: '20px' }} className='Paper'>
<h2 variant="h6">
  Mejor hora para usar:
</h2>
<h1 variant="body1">
  {bestTime.hour}:00 - {bestTime.hour + parseInt(duration)}:00
</h1>
<h3 variant="body1">
  Precio estimado: { (bestTime.cost / 1000).toFixed(2) } €
</h3>
<h3 variant="body1">
  Precio estimado por mes: { (bestTime.cost / 1000 * 30).toFixed(2) } €
</h3>

      </Paper>
    )}

  </Container>

      {/* Notifications Snackbar */}
      {tableSaved && (
console.log("La tabla se ha guardado en la base de datos local exitosamente.")
)}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        message="¡El precio de la electricidad es muy bajo! ¡Es un buen momento para consumir energía!"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleCloseNotification}>
              Cerrar
            </Button>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notificationTooHigh}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        message="¡El precio de la electricidad es bastante alto! ¡No gastes mucha energía!"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleCloseNotification}>
              Cerrar
            </Button>
          </React.Fragment>
        }
      />

      {/* Footer */}
<footer className="footer">
  <Container maxWidth="md">
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={4} md={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          <a href="https://precionline.com" className="footer-link">
            <strong>PreciOnline</strong>
          </a>
          <br />
          Licenciado bajo
          <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" className="footer-link">
            <strong> CC BY-NC-ND 4.0</strong>
          </a>
        </Typography>
        <br />
        <div className="footer-links">
          <a href="#" onClick={toggleDisclaimer}>Exención de Responsabilidad</a><br />
          <a href="#" onClick={toggleLegalNotice}>Términos</a><br />
          <a href="#" onClick={toggleCookiePolicy}>Política de Cookies</a><br />
          <a href="https://precionline.com/politica-privacidad.html" target="_blank">Política de Privacidad</a>
        </div>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          <strong>Fuente:</strong> Red Eléctrica de España. Precios de electricidad en kWh para la península, actualizados diariamente. <strong>PVPC 2.0 TD</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Typography variant="body2" color="textSecondary" align="center">
          <strong>Versión 1.12.1 (Despliegue 121)</strong>
          <br />
          <a href='https://precionline.instatus.com/' target="_blank" rel="noopener noreferrer" className="footer-link">
            <strong>Estado de la aplicación</strong>
          </a>
        </Typography>
      </Grid>
    </Grid>
  </Container>
</footer>

    </Container>
  );
};

const findCheapestTwoHours = (data) => {
  if (data.length < 2) return { startHour: null, averagePrice: null };

  let cheapestPeriod = { startHour: null, averagePrice: Infinity };

  for (let i = 0; i < data.length - 1; i++) {
    const currentHour = new Date(data[i].datetime).getHours();
    const nextHour = new Date(data[i + 1].datetime).getHours();

    // Verifica si las horas están dentro del rango permitido (7:00 a 22:00)
    if (currentHour >= 7 && currentHour <= 21 && nextHour >= 8 && nextHour <= 22) {
      const currentAvgPrice = (data[i].value + data[i + 1].value) / 2;
      if (currentAvgPrice < cheapestPeriod.averagePrice) {
        cheapestPeriod = { startHour: currentHour, averagePrice: currentAvgPrice };
      }
    }
  }

  return cheapestPeriod;
};

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* animations.css */

.fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
  `, "",{"version":3,"sources":["webpack://./src/animations.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,UAAU;EACZ;EACA;IACE,UAAU;IACV,yBAAyB;EAC3B;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;IACV,yBAAyB;EAC3B","sourcesContent":["/* animations.css */\r\n\r\n.fade-enter {\r\n    opacity: 0;\r\n  }\r\n  .fade-enter-active {\r\n    opacity: 1;\r\n    transition: opacity 500ms;\r\n  }\r\n  .fade-exit {\r\n    opacity: 1;\r\n  }\r\n  .fade-exit-active {\r\n    opacity: 0;\r\n    transition: opacity 500ms;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
